import React from 'react'
import LazySVG from './LazySVG'

const svgString = `
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 640 225" text-rendering="geometricPrecision" shape-rendering="geometricPrecision" style="white-space: pre;">
    <defs>
        <symbol id="Symbol-1" preserveAspectRatio="none" width="79.3333" height="79.3333" viewBox="0 0 79.3333 79.3333" overflow="visible">
            <path fill-rule="evenodd" d="M6,3L0,8L6,13L6,10L10,10L10,6L6,6Z" fill="#e9ecef" transform="translate(39.6667,39.6667) scale(7.93333,7.93333) translate(-5,-8)"/>
        </symbol>
    </defs>
    <g id="container" transform="translate(320,96.9152) translate(-146,-67)">
        <rect width="292" height="134" fill="#e9ecef" stroke="none" transform="translate(157,-11.5) translate(-157,11.5)"/>
        <rect width="292" height="12" fill="#adb5bd" stroke="none" transform="translate(146,9) translate(-146,-9)"/>
        <rect width="292" height="12" fill="#adb5bd" stroke="none" transform="translate(146,131) translate(-146,-9)"/>
    </g>
    <g>
        <text id="enqueue" fill="#000000" font-size="24" font-family="Helvetica" font-weight="400" letter-spacing="0em" word-spacing="0em" text-anchor="middle" stroke="none" opacity="0" transform="translate(320,202.48)" style="line-height: 16px;">Enqueue</text>
        <text id="dequeue" fill="#000000" font-size="24" font-family="Helvetica" font-weight="400" letter-spacing="0em" word-spacing="0em" text-anchor="middle" stroke="none" opacity="0" transform="translate(320,202.48)" style="line-height: 16px;">Dequeue</text>
    </g>
    <path fill-rule="evenodd" d="M6,2L0,8L6,14Z" fill="#dee2e6" transform="translate(174,96.9152) scale(19.3333,9.09698) translate(0,-8)"/>
    <path fill-rule="evenodd" d="M6,2L0,8L6,14Z" fill="#dee2e6" transform="translate(466,96.9152) scale(19.3333,9.09698) translate(-6,-8)"/>
    <path fill-rule="evenodd" d="M6,2L0,8L6,14Z" fill="#dee2e6" transform="translate(320,96.9152) scale(19.3333,9.09698) translate(-3,-8)"/>
    <g transform="translate(294,97) translate(-315,-96.9576)">
        <rect id="item1" width="40" height="86" fill="#339af0" stroke="none" fill-rule="nonzero" fill-opacity="1" stroke-opacity="1" opacity="1" transform="translate(260.375,96.8728) translate(0,0) rotate(180) translate(-20,-43)"/>
        <rect id="item1-2" width="40" height="86" fill="#339af0" stroke="none" fill-rule="nonzero" fill-opacity="1" stroke-opacity="1" opacity="1" transform="translate(314.125,96.8728) translate(0,0) rotate(180) translate(-20,-43)"/>
        <rect id="item1-3" width="40" height="86" fill="#339af0" stroke="none" fill-rule="nonzero" fill-opacity="1" stroke-opacity="1" opacity="1" transform="translate(367.875,96.8728) translate(0,0) rotate(180) translate(-20,-43)"/>
        <rect id="item1-4" width="40" height="86" fill="#339af0" stroke="none" fill-rule="nonzero" fill-opacity="1" stroke-opacity="1" opacity="1" transform="translate(421.625,96.8728) translate(0,0) rotate(180) translate(-20,-43)"/>
        <rect id="item1-5" width="40" height="86" fill="#339af0" stroke="none" fill-rule="nonzero" fill-opacity="1" stroke-opacity="1" opacity="0" transform="translate(559,96.8728) translate(0,0) rotate(180) translate(-20,-43)"/>
    </g>
    <script><![CDATA[window.ks=function(){function E(a){return"undefined"!==typeof a}function v(a,b){return a&&0==a.indexOf(b)}function N(a){if(!isFinite(a))throw"non-finite value";}function O(a){if(14>=a)return 16;(a=X[a])||(a=0);return a}function C(a){return 0<=a?Math.pow(a,1/3):-Math.pow(-a,1/3)}function Y(a,b,c,d){if(0==a)return 0==b?b=-d/c:(a=Math.sqrt(c*c-4*b*d),d=(-c+a)/(2*b),0<=d&&1>=d?b=d:(d=(-c-a)/(2*b),b=0<=d&&1>=d?d:0)),b;var e=c/a-b*b/(a*a)/3;c=b*b*b/(a*a*a)/13.5-b*c/(a*a)/3+d/a;var k=c*c/4+e*e*e/27;b=-b/
(3*a);if(0>=k){if(0==e&&0==c)return-C(d/a);a=Math.sqrt(c*c/4-k);d=Math.acos(-c/2/a);c=Math.cos(d/3);d=Math.sqrt(3)*Math.sin(d/3);a=C(a);e=2*a*c+b;if(0<=e&&1>=e)return e;e=-a*(c+d)+b;if(0<=e&&1>=e)return e;e=a*(d-c)+b;if(0<=e&&1>=e)return e}else{a=C(-c/2+Math.sqrt(k));c=C(-c/2-Math.sqrt(k));d=a+c+b;if(0<=d&&1>=d)return d;d=-(a+c)/2+b;if(0<=d&&1>=d)return d}return 0}function Z(a,b){if(48==a&&"number"===typeof b)return"#"+("000000"+b.toString(16)).substr(-6);if(64==a)return b=b.map(function(a){return a+
"px"}),b.join(",");if(96==a){a="";for(var c=b.length,d=0;d<c;d+=2)a+=b[d],a+=b[d+1].join(",");return a}if(80==a){if(0==b[0])return"none";a="";c=b.length;for(d=0;d<c;)a+=R[b[d]],1==b[d]?a+="("+b[d+1]+") ":5==b[d]?(a+="("+b[d+1]+"px "+b[d+2]+"px "+b[d+3]+"px rgba("+(b[d+4]>>>24)+","+(b[d+4]>>16&255)+","+(b[d+4]>>8&255)+","+(b[d+4]&255)/255+")) ",d+=3):a=2==b[d]?a+("("+b[d+1]+"px) "):7==b[d]?a+("("+b[d+1]+"deg) "):a+("("+(0>b[d+1]?0:b[d+1])+") "),d+=2;return a}return 32==a?b+"px":b}function w(a){return 0>=
a?0:255<=a?255:a}function aa(a,b,c,d){if(16==a||32==a)return(c-b)*d+b;if(0==a)return.5>d?b:c;if(48==a){if("number"===typeof b&&"number"===typeof c){var e=1-d;return w(e*(b>>16)+d*(c>>16))<<16|w(e*(b>>8&255)+d*(c>>8&255))<<8|w(e*(b&255)+d*(c&255))}return.5>d?b:c}if(64==a){0==b.length&&(b=[0]);0==c.length&&(c=[0]);var k=b.length;b.length!=c.length&&(k=b.length*c.length);var g=[];for(a=0;a<k;++a){var f=b[a%b.length];var h=(c[a%c.length]-f)*d+f;0>h&&(h=0);g.push(h)}return g}if(96==a){if(b.length!=c.length)return.5>
d?b:c;k=b.length;g=[];for(a=0;a<k;a+=2){if(b[a]!==c[a])return.5>d?b:c;g[a]=b[a];g[a+1]=[];for(f=0;f<b[a+1].length;++f)g[a+1].push((c[a+1][f]-b[a+1][f])*d+b[a+1][f])}return g}if(80==a){k=b.length;if(k!=c.length)return.5>d?b:c;g=[];for(a=0;a<k;){if(b[a]!=c[a]||1==b[a])return.5>d?b:c;g[a]=b[a];g[a+1]=(c[a+1]-b[a+1])*d+b[a+1];if(5==b[a]){g[a+2]=(c[a+2]-b[a+2])*d+b[a+2];g[a+3]=(c[a+3]-b[a+3])*d+b[a+3];e=1-d;var l=b[a+4],q=c[a+4];h=e*(l>>>24)+d*(q>>>24);var m=e*(l>>16&255)+d*(q>>16&255);f=e*(l>>8&255)+
d*(q>>8&255);g[a+4]=(w(m)<<16|w(f)<<8|w(e*(l&255)+d*(q&255)))+16777216*(w(h)|0);a+=3}a+=2}return g}return 0}function S(a,b){a:{var c=a+b[2];var d=b[4].length;for(var e=0;e<d;++e)if(c<b[4][e]){c=e;break a}c=d-1}d=b[2];e=b[4][c-1]-d;a=(a-e)/(b[4][c]-d-e);if(b[6]&&b[6].length>c-1)if(d=b[6][c-1],1==d[0])if(0>=a)a=0;else if(1<=a)a=1;else{e=d[1];var k=d[3];a=Y(3*e-3*k+1,-6*e+3*k,3*e,-a);a=3*a*(1-a)*(1-a)*d[2]+3*a*a*(1-a)*d[4]+a*a*a}else 2==d[0]?(d=d[1],a=Math.ceil(a*d)/d):3==d[0]&&(d=d[1],a=Math.floor(a*
d)/d);return aa(b[1]&240,b[5][c-1],b[5][c],a)}function P(){z=(new Date).getTime()}function J(a){for(var b=!1,c=0;c<x.length;++c)x[c].F(a)&&(b=!0);a&&x.forEach(function(a){a.l&&(a.l=!1,a.onfinish&&(a.onfinish(),b=!0))});return b}function T(){P();J(!0)?(K=!0,L(T)):K=!1}function Q(){K||(K=!0,L(T))}function U(a,b){var c=[];a.split(b).forEach(function(a){c.push(parseFloat(a))});return c}function t(a){-1==a.indexOf(",")&&(a=a.replace(" ",","));return U(a,",")}function V(a){a._ks||(a._ks={H:M},++M);if(!a._ks.transform){for(var b=
a._ks.transform=[],c=0;14>=c;++c)b[c]=0;if(c=a.getAttribute("transform")){for(c=c.trim().split(") ");0<a._ks.w;)c.shift(),--a._ks.w;a=c.shift();v(a,"translate(")&&(a=t(a.substring(10)),b[1]=a[0],b[2]=a[1],a=c.shift());v(a,"rotate(")&&(a=t(a.substring(7)),b[6]=a[0],a=c.shift());v(a,"skewX(")&&(a=t(a.substring(6)),b[7]=a[0],a=c.shift());v(a,"skewY(")&&(a=t(a.substring(6)),b[8]=a[0],a=c.shift());v(a,"scale(")&&(a=t(a.substring(6)),b[10]=a[0],b[11]=a[1],a=c.shift());v(a,"translate(")&&(a=t(a.substring(10)),
b[13]=a[0],b[14]=a[1])}}}function W(a){this.C=a;this.v=[];this.o=[];this.g=0;this.i=this.a=this.b=null;this.f=this.A=this.l=this.h=!1}function G(a,b,c){b=a[b];void 0===b&&(b=a[c]);return b}function ba(a){return Array.isArray(a)?a:v(a,"cubic-bezier(")?(a=a.substring(13,a.length-1).split(","),[1,parseFloat(a[0]),parseFloat(a[1]),parseFloat(a[2]),parseFloat(a[3])]):v(a,"steps(")?(a=a.substring(6,a.length-1).split(","),[a[1]&&"start"==a[1].trim()?2:3,parseFloat(a[0])]):[0]}function ca(a){a=a.trim();return v(a,
"#")?(parseInt(a.substring(1),16)<<8)+255:v(a,"rgba(")?(a=a.substring(5,a.length-1),a=a.split(","),(parseInt(a[0],10)<<24)+(parseInt(a[1],10)<<16)+(parseInt(a[2],10)<<8)+255*parseFloat(a[3])<<0):a}var da=" translate translate    rotate skewX skewY  scale scale  translate translate".split(" "),R="none url blur brightness contrast drop-shadow grayscale hue-rotate invert opacity saturate sepia".split(" "),L=window.requestAnimationFrame||window.webkitRequestAnimationFrame||window.mozRequestAnimationFrame||
window.oRequestAnimationFrame||window.msRequestAnimationFrame||null;L||(L=function(a){window.setTimeout(a,16)});var M=0,X={d:97,fill:48,fillOpacity:16,filter:80,height:33,opacity:16,stroke:48,strokeDasharray:64,strokeDashoffset:32,strokeOpacity:16,strokeWidth:32,transform:1,width:33},K=!1,z=(new Date).getTime(),x=[];W.prototype={j:function(a){var b=!1;if(null!==this.b){var c=this.c();null!==c&&c>=this.g?(b=!0,a?this.a=c:this.a=this.i?Math.max(this.i,this.g):this.g):null!==c&&(a&&null!==this.a&&(this.b=
z-this.a/1),this.a=null)}this.i=this.c();return b},F:function(a){a&&(this.h&&(this.h=!1,null===this.b&&(null!==this.a?(this.b=z-this.a/1,this.a=null):this.b=z)),null===this.a&&null!==this.b&&this.j(!1)&&(this.l=!0));a=this.c();if(null===a)return!1;for(var b=this.v,c=this.o,d=0;d<b.length;++d){for(var e=b[d],k=!1,g=0;g<c[d].length;++g){var f=c[d][g],h=f[0];if(null!==h){var l=f[2];var q=f[4].length,m=f[4][q-1]-l;l=0==m?f[5][q-1]:a<=l?f[5][0]:a>=l+f[3]?0==f[3]%m?f[5][q-1]:S(f[3]%m,f):S((a-l)%m,f);0==
h?(e._ks.mpath=f[8],e._ks.transform[h]=l,k=!0):14>=h?(e._ks.transform[h]=l,k=!0):(l=Z(f[1]&240,l),f[1]&1?e.setAttribute(h,l):e.style[h]=l)}}if(k){V(e);k=e._ks.transform;g="";if(f=e._ks.mpath)l=k[0]*f[2]/100,h=f[1].getPointAtLength(l),g="translate("+h.x+","+h.y+") ",f[0]&&(.5>l?(l=h,h=f[1].getPointAtLength(.5)):l=f[1].getPointAtLength(l-.5),g+="rotate("+180*Math.atan2(h.y-l.y,h.x-l.x)/Math.PI+") ");for(f=1;f<k.length;++f)if(h=k[f])g+=" "+da[f]+"(",g=2>=f?g+(1==f?h+",0":"0,"+h):13<=f?g+(13==f?h+",0":
"0,"+h):10<=f?g+(10==f?h+",1":"1,"+h):g+h,g+=")";e.setAttribute("transform",g)}}return"running"==this.B()},s:function(){if(!this.A){this.A=!0;for(var a=this.v,b=this.o,c=0;c<a.length;++c)for(var d=a[c],e=!1,k=0;k<b[c].length;++k){var g=b[c][k][0];14>=g?d._ks.G||(V(d),d._ks.G=d._ks.transform.slice()):(d._ks.m||(d._ks.m={}),E(d._ks.m[g])||(e||(e=getComputedStyle(d)),d._ks.m[g]=O(g)&1?d.getAttribute(g):e[g]))}}},play:function(a){E(a)&&this.u(a,!0);if(!this.f)return this;a=this.c();if(null===a||0>a||
a>=this.g)this.a=0;if(null===this.a)return this;this.b=null;this.h=!0;this.s();Q();return this},pause:function(a){E(a)&&this.u(a,!0);if(!this.f||"paused"==this.B())return this;E(a)||P();a=this.c();null===a&&(this.a=0);null!==this.b&&null===this.a&&(this.a=a);this.b=null;this.h=!1;this.j(!1);this.s();J(!1);return this},c:function(){return null!==this.a?this.a:null===this.b?null:1*(z-this.b)},u:function(a,b){N(a);b&&P();null!==a&&(this.s(),null!==this.a||null===this.b?(this.a=a,J(!1)):this.b=z-a/1,
this.f||(this.b=null),this.i=null,this.j(!0),Q())},I:function(){return this.c()},time:function(a){return E(a)?(this.f&&this.u(a,!0),this):this.I()},startTime:function(a){if(E(a)){N(a);if(!this.f)return this;this.i=this.c();this.b=a;null!==a?this.a=null:this.a=this.i;this.h=!1;this.j(!0);J(!1);Q();return this}return this.D?this.D[0].startTime:this.b},B:function(){var a=this.c();return this.h?"running":null===a?"idle":null===this.b?"paused":a>=this.g?"finished":"running"}};return{setmptr:function(a){for(var b in a){var c=
document.getElementById(b);c._ks||(c._ks={});c._ks.w=a[b]}},animate:function(){if(0<x.length)throw"data already set";var a={};if(1==arguments.length%2){a=arguments[arguments.length-1];var b={};for(c in a)b[c]=a[c];a=b}var c=new W(a);a=arguments;for(var d=b=0;d<a.length-1;d+=2){var e=a[d];var k=e instanceof Element?e:document.getElementById(e.substring(1));if(!k)throw"invalid target: "+e;e=k;k=a[d+1];e._ks||(e._ks={H:M},++M);for(var g=[],f=0;f<k.length;++f){var h=k[f],l=G(h,"p","property");14>=l||
-1==l.indexOf("-")||(l=null);var q=O(l);q||(q=0);var m=G(h,"t","times");if(!m||2>m.length)throw"not enough times";m=m.slice();if(!isFinite(m[0])||0>m[0])throw"bad time: "+m[0];for(var y=1;y<m.length;++y)if(!isFinite(m[y])||0>m[y]||m[y]<m[y-1])throw"bad time: "+m[y];y=m[0];var w=m[m.length-1]-y,z=h.iterations||0;1>z&&(z=1);w*=z;b<w+y&&(b=w+y);var t=G(h,"v","values");if(!t||t.length!=m.length)throw"values don't match times";t=t.slice();for(var A=l,n=t,H=O(A)&240,p=0;p<n.length;++p)if(96==H){for(var F=
n[p].substring(6,n[p].length-2).match(/[A-DF-Za-df-z][-+0-9eE., ]*/ig),I=[],r=0;r<F.length;++r){I.push(F[r][0]);for(var u=1<F[r].trim().length?F[r].substring(1).split(","):[],D=0;D<u.length;++D)u[D]=parseFloat(u[D]);I.push(u)}n[p]=I}else if(48==H)if(v(n[p],"#"))n[p]=parseInt(n[p].substring(1),16);else{if(!v(n[p],"url(")&&"none"!=n[p])throw"invalid color: "+n[p];}else if(80==H){F=n;I=p;r=n[p];if("none"==r)r=[0];else{u=[];for(var B=r.indexOf("(");0<B;)if(D=R.indexOf(r.substring(0,B)),0<=D){u.push(D);
var C=r.indexOf(") ");0>C&&(C=r.length-1);B=r.substring(B+1,C).split(" ");5==D?(u.push(parseFloat(B[0])),u.push(parseFloat(B[1])),u.push(parseFloat(B[2])),u.push(ca(B[3]))):1==D?u.push(B[0]):u.push(parseFloat(B[0]));r=r.substring(C+1).trim();B=r.indexOf("(")}else break;r=u}F[I]=r}else if(64==H)if("none"!=n[p]){if(!/^[0-9 .]*$/.test(n[p]))throw"bad value: "+n[p];n[p]=U(n[p]," ")}else n[p]=[0];else 32==H?(N(n[p]),n[p]=parseFloat(n[p])):0===A&&(n[p]=parseFloat(n[p]));A=G(h,"e","easing");n=m.length;for(A||
(A=[]);A.length<n;)A.push([1,0,0,.58,1]);for(n=0;n<A.length;++n)A[n]=ba(A[n]);q=[l,q,y,w,m,t,A,z];m=G(h,"m","motionPath");E(m)&&0===l&&(q[8]=[],q[8][0]=h.motionRotate,h=document.createElementNS("http://www.w3.org/2000/svg","path"),m||(m="M0,0"),h.setAttribute("d",m),q[8][1]=h,q[8][2]=h.getTotalLength());g.push(q)}0<g.length&&(c.v.push(e),c.o.push(g))}c.g=b;!1===c.f&&(x.push(c),c.f=!0,!1!==c.C.autoplay&&c.play());return c},_priv_list:function(){return x.slice()},play:function(){return x[0]?x[0].play():
this},pause:function(){return x[0]?x[0].pause():this},time:function(a){return x[0]?x[0].time(a):E(a)?this:null}}}();
document.ks=ks;(function(ks){
ks.setmptr({'item1':1,'item1-2':1,'item1-3':1,'item1-4':1,'item1-5':1});ks.animate("#enqueue",[{p:'opacity',t:[0,1000,1500,2500],v:[0,1,1,0],e:[[0],[0],[0],[0]]}],
"#dequeue",[{p:'opacity',t:[3000,4000,4500,5500],v:[0,1,1,0],e:[[0],[0],[0],[0]]}],
"#item1",[{p:0,t:[500,1500,3500,4500],v:['0%','19.970455%','19.970455%','100%'],e:[[1,0,0,0.58,1],[1,0,0,0.58,1],[1,0,0,0.58,1],[0]],m:"M260.375,96.8728L233.34,96.8728L233.34,96.8728L125,96.8728"},{p:'opacity',t:[3500,4500],v:[1,0],e:[[0],[0]]}],
"#item1-2",[{p:0,t:[500,1500,3500,4500],v:['0%','50.148399%','50.148399%','100%'],e:[[1,0,0,0.58,1],[1,0,0,0.58,1],[1,0,0,0.58,1],[0]],m:"M314.125,96.8728L287.09,96.8728L287.09,96.8728L260.215,96.8728"}],
"#item1-3",[{p:0,t:[500,1500,3500,4500],v:['0%','50.148399%','50.148399%','100%'],e:[[1,0,0,0.58,1],[1,0,0,0.58,1],[1,0,0,0.58,1],[0]],m:"M367.875,96.8728L340.84,96.8728L340.84,96.8728L313.965,96.8728"}],
"#item1-4",[{p:0,t:[500,1500,3500,4500],v:['0%','50.148399%','50.148399%','100%'],e:[[1,0,0,0.58,1],[1,0,0,0.58,1],[1,0,0,0.58,1],[0]],m:"M421.625,96.8728L394.59,96.8728L394.59,96.8728L367.715,96.8728"}],
"#item1-5",[{p:0,t:[500,1500,3500,4500],v:['0%','80.45952%','80.45952%','100%'],e:[[1,0,0,0.58,1],[1,0,0,0.58,1],[1,0,0,0.58,1],[0]],m:"M559,96.8728L448.34,96.8728L448.34,96.8728L421.465,96.8728"},{p:'opacity',t:[500,1500],v:[0,1],e:[[0],[0]]}],
{autoplay:document.location.search.substr(1).split('&').indexOf('autoplay=false')<0})
})(ks);
]]></script>
</svg>
`

export default function QueueSVG() {
  return <LazySVG svgString={svgString} />
}
